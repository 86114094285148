import { environment } from '../../../environments/environment';

const url = `${environment.host}/${environment.prefix}`;
export const domainName = environment.domain;

/**
 * API settings
 */
export const api = {
  // Authentication API
  signIn: url + '/sign-in',
  signUp: url + '/sign-up',
  protected: url + '/protected',
  unlockSession: url + '/unlock-session',
  refreshToken: url + '/refresh-token',
  forgotPassword: url + '/forgot-password',
  resetPassword: url + '/reset-password',
  validationCode: url + '/validation-code',
  confirmationCode: url + '/confirmation',
  changePassword: url + '/change-password',
  changeLanguage: url + '/change-language',
  changeLayout: url + '/change-layout',

  // User API
  currentUser: url + '/user',
  user: url + '/user',
  users: url + '/users',
  groups: url + '/groups',

  // Movie API
  movies: url + '/movies',
  movieWatchHistorical: url + '/movie-watchHistorical',
  movieVideo: url + '/movie-videoFiles',

  // Episode API
  episodes: url + '/episodes',
  episodeWatchHistorical: url + '/episode-watchHistorical',
  episodeVideo: url + '/episode-videoFiles',

  // Other API
  collaborators: url + '/collaborators',
  genres: url + '/genres',
};
